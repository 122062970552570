<template>
  <div class="shop">
    <left-tabs :tabList="leftList" />
    <router-view></router-view>
  </div>
</template>

<script>
import LeftTabs from "@/components/leftTabs";
export default {
  components: { LeftTabs },
  name: "Courses",
  data() {
    return {
      leftList: [
        { id: 1, name: "课程列表", url: '/Courses/list' },
        { id: 2, name: "课程分类", url: '/Courses/cate' },
		// { id: 2, name: "课程内容", url: '/Courses/addnewslist' }
      ],
    };
  },
  methods: {},

};
</script>

<style scoped lang='less'>
  .shop {
    display: flex;
  }
</style>